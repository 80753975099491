import React from "react";
import { SeatMap, SeatMapProps } from "./SeatMap";
import { ModalBody } from "@components/Modal";

export type SeatSelectionModalBodyProps = SeatMapProps;

export const SeatSelectionModalBody: React.FC<SeatSelectionModalBodyProps> = (
  props
) => (
  <ModalBody>
    <SeatMap {...props} />
  </ModalBody>
);
;
import "/Users/igor/Duffel/ancillaries-component/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";