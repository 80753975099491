import { OfferSliceSegment } from "src/types/Offer";

export const getPassengerBySegmentList = (segments: OfferSliceSegment[]) =>
  segments.flatMap((segment) =>
    segment.passengers.map((passenger, passengerIndex) => ({
      passenger,
      passengerIndex,
      segment,
    }))
  );
;
import "/Users/igor/Duffel/ancillaries-component/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";