import React from "react";

export const CfarSelectionModalHeader: React.FC = () => (
  <div style={{ padding: "24px 24px 16px" }}>
    <h2 className="h3--semibold" style={{ marginBlock: 0 }}>
      Cancel for any reason
    </h2>
  </div>
);
;
import "/Users/igor/Duffel/ancillaries-component/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";