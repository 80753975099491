import {
  OfferAvailableService,
  OfferAvailableServiceCancelForAnyReason,
} from "src/types/Offer";

export const isCancelForAnyReasonService = (
  service: OfferAvailableService
): service is OfferAvailableServiceCancelForAnyReason =>
  service.type === "cancel_for_any_reason";
;
import "/Users/igor/Duffel/ancillaries-component/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";