import { OfferAvailableService } from "src/types/Offer";

export type ServicePriceMapById = Record<
  OfferAvailableService["id"],
  Pick<OfferAvailableService, "total_amount" | "total_currency">
>;

export const getServicePriceMapById = (
  availableServices: OfferAvailableService[]
): ServicePriceMapById =>
  availableServices.reduce(
    (all, { id, total_amount, total_currency }) => ({
      [id]: {
        total_amount,
        total_currency,
      },
      ...all,
    }),
    {} as ServicePriceMapById
  );
;
import "/Users/igor/Duffel/ancillaries-component/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";