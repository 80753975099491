import { Icon } from "@components/Icon";
import React, { ReactNode } from "react";

export const CfarSelectionModalBodyListItem: React.FC<{
  children: ReactNode;
}> = ({ children }) => (
  <li className="cfar-modal-list-item">
    <Icon name="check" />
    <p>{children}</p>
  </li>
);
;
import "/Users/igor/Duffel/ancillaries-component/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";